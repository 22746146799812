// Google Map



function initMap() {
    var map;

    var address = encodeURIComponent($('#contacts-city').text() +' '+$('#contacts-street').text());

    var myLatLng = {};

    $.ajax({
        url: "https://maps.googleapis.com/maps/api/geocode/json?address="+address+"&key=AIzaSyAIIFIlV3f3NHpmFPw5Dr0Wyr9ZcaeyvNk",
        type: "GET",
        cache: false,

        success: function(response){
            myLatLng = {lat: response.results[0].geometry.location.lat , lng: response.results[0].geometry.location.lng};
        },
        complete: function() {
            // Create a map object and specify the DOM element for display.
            var map = new google.maps.Map(document.getElementById('map'), {
                center: myLatLng,
                zoom: 18,
                disableDefaultUI: true,
                scrollwheel: false
            });
            var marker = new google.maps.Marker({
                position: myLatLng,
                map: map,
                title: 'Cinelab',
                icon: {
                    url: "/img/loc-icon.png",
                    scaledSize: new google.maps.Size(46, 66)
                }
            });

            var styles = [
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#D3D3D3"
                    }
                ]
            }];

            map.setOptions({styles: styles});
        }
        
    }); 
    
    
}

